import * as React from "react"
import { Link } from "gatsby"
import ContactForm from "../components/Home/Contact/ContactForm"
import Layout from "../components/layout"
import MastheadImage from "../images/mockup-smartpro.png"
import Screenshot1 from "../images/smartpro-screenshot-1.png"
import Screenshot2 from "../images/smartpro-screenshot-2.png"
import OtherWork2 from "../images/actionera-mockup.png"
import OtherWork1 from "../images/qoi-mockup.png"
import "./page.css"

const WorkSmartPro = () => {

  return (
    <div>
      <Layout showHeader selectedHeader={`OurWorks`}>
        <section id="header" className="our-works-content min-h-screen flex flex-col">
          <div className="container mx-auto pt-20 pb-10 px-8 lg:pt-0 lg:pb-12">
            <h1 className="heading uppercase lg:font-semibold text-4xl md:text-7xl">
              Our Works
            </h1>
          </div>
          <div className="w-full work-preview">
            <div className="mobile-wrap" style={{ backgroundImage: 'url(' + MastheadImage + ')'}}>
              <img src={MastheadImage} alt="Preview of Smart-Pro Cleaners"/>
            </div>
          </div>
          <div className="w-full bg-white">
            <div className="container mx-auto w-full px-8 py-8 lg:px-0 lg:pt-12 pb-18">
              <div className="block lg:flex lg:justify-between">
                <div className="works-title text-6xl font-bold lg:w-1/2 text-[#707070]">
                  Smart-Pro Cleaners
                </div>
                <div>
                  <div className="works-type-gradient inline-block rounded-full p-4 mx-2 text-white">
                    Web Design
                  </div>
                  <div className="works-type-gradient  inline-block rounded-full p-4 mx-2 text-white">
                    Web Development
                  </div>

                </div>

              </div>     
              <div className="work-body py-8">
                <a href="https://smartprocleaners.com/" target="_blank" rel="noreferrer">Smart-Pro Cleaners</a> is a company that offers high-quality cleaning services for a wide array of clients, and with the help of their website, they communicate their brand of professionalism, excellence, and trustworthiness. We customized their site with features that made browsing and booking simpler and more convenient for both the company and their clients.
              </div>
              <div className="flex justify-center">
                <img src={Screenshot1} alt="Screenshot of Smart-Pro Cleaners"/>
              </div>
              <div className="work-body py-8">
                The website features a widget that guides clients on what service they’re looking to get based on their specific needs. It also includes a “Get a Quote” feature which lets clients fill out a form with their details, and send pictures of the property. The form then produces an automated price estimate that, once checked by the staff, can immediately be sent to the clients. The website also features an admin page where staff can login to view and manage quote requests, past jobs, and client reviews. Through the admin page, the company can easily update their website by featuring photos of finished jobs and reviews from clients.
              </div>
              <div className="flex justify-center">
                <img src={Screenshot2} alt="Screenshot of Smart-Pro Cleaners"/>
              </div>
              <div className="work-body py-8">
              Nowadays, Smart-Pro Cleaners gets constantly fully-booked ahead for months. They were able to close contracts from multiple big-name clients ranging from studios, high-end commercial buildings, a popular sports team, as well as Airbnb jobs. According to the owner, the website helps greatly in conveying the company’s professionalism and credibility and gives her confidence whenever a potential client will reach out to her and staff to know more about them.
              </div>
              <div className="flex flex-col lg:flex-row justify-center items-center lg:items-start">



              </div> 
            </div>
          </div>
        </section>
        <div className="other-works-container w-full">
          <section id="other-works" className="container mx-auto w-full px-8 py-8 lg:px-0 lg:pt-12 pb-32">
            <h3 className="heading text-5xl py-8">View more works</h3>
            <div className="other-work-cards flex gap-4 content-center text-center">
              <Link to="/work-qoi" className="other-work" style={{backgroundImage: 'url(' + OtherWork1 + ')'}}>
                  <span>QOI SYSTEMS INTEGRATION</span>
              </Link>
              <Link to="/work-actionera" className="other-work " style={{backgroundImage: 'url(' + OtherWork2 + ')'}}>
                  <span>ACTIONERA</span>
              </Link>
            </div>
          </section>
        </div>

        <ContactForm />
      </Layout>
    </div>
  )
}

export default WorkSmartPro
